//
// Co3
//
// Copyright:: (c) 2022 Innotronic Ingenieurbüro GmbH

import { Controller } from "@hotwired/stimulus";
import { patch } from '@rails/request.js'

export default class extends Controller
{
  static targets = [ 'zeitVon', 'zeitBis', 'zeitPause', 'zeitDauer' ];
  static values = { 'url': String };

  initialize()
  {
    this.update = this.update.bind( this );
  }

  zeitVonTargetConnected( element )
  {
    element.addEventListener( 'blur', this.update );
  }

  zeitBisTargetConnected( element )
  {
    element.addEventListener( 'blur', this.update );
  }

  zeitPauseTargetConnected( element )
  {
    element.addEventListener( 'blur', this.update );
  }

  // zeitDauerTargetConnected( element )
  // {
  //   element.addEventListener( 'change', this.update );
  // }


  async update( event )
  {
    const requestData = new FormData( this.element );
    const response = await patch( this.url, { body: requestData, responseKind: 'json' } );

    if( response.ok )
    {
      const responseData = await response.json;

      if( this.hasZeitVonTarget && 'zeit_von' in responseData )
      {
        this.zeitVonTarget.value = responseData.zeit_von;
      }

      if( this.hasZeitBisTarget && 'zeit_bis' in responseData )
      {
        this.zeitBisTarget.value = responseData.zeit_bis;
      }

      if( this.hasZeitPauseTarget && 'zeit_pause' in responseData )
      {
        this.zeitPauseTarget.value = responseData.zeit_pause;
      }

      if( this.hasZeitDauerTarget )
      {
        if( 'zeit_dauer' in responseData )
        {
          this.zeitDauerTarget.value = responseData.zeit_dauer;
        }

        if( 'zeit_dauer_hint' in responseData )
        {
          this.zeitDauerTarget.placeholder = responseData.zeit_dauer_hint;
        }

        if( 'zeit_dauer_disable' in responseData )
        {
          this.zeitDauerTarget.readOnly = !!responseData.zeit_dauer_disable;
        }
      }
    }
  }

  get url()
  {
    return this.hasUrlValue ? this.urlValue : this.element.action;
  }
}